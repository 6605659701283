import { useEffect, useState } from 'react';
import './style.css';
import {Add, Delete, LocationOn} from '@material-ui/icons'
function App() {
const [initialGrocery, setinitialGrocery] = useState([])
const [groceryList, setgroceryList] = useState([])
const [addGrocery, setaddGrocery] = useState(false)
const [groceryName, setgroceryName] = useState('')
const [storeLocation, setstoreLocation] = useState('');
const [flashMessage, setflashMessage] = useState([false, '', []])

useEffect(() => {
  getGroceries()
}, [])


const getGroceries = () => {
  const url = "/api/get-grocery.php";
  fetch(url, {
    method: "POST"
  }).then((res) => res.json()).then((data) => {
    setinitialGrocery(data)
    setgroceryList(data)
  })
}


const handelSubmit = () => {
  if (groceryName == '' || storeLocation == '') {
    setflashMessage([true, 'Please Enter All Fields', 'red'])
    setaddGrocery(false)
    setTimeout(() => {
      setflashMessage([false, '', []])
    }, 4000);
    return;
  }
  const formData = new FormData
  const url = '/api/post-grocery.php';
  formData.append("groceryName", groceryName);
  formData.append("storeLocation", storeLocation);
  fetch(url, {
    method: 'POST',
    body: formData
  }).then((res) => res.json()).then((data) => {
    if (data == 'Success') {
      setflashMessage([true, 'Grocery Added', 'green'])
    } else {
      setflashMessage([true, 'Error Occured When Adding Grocery', 'red'])
    }
    
    setaddGrocery(false); 
    getGroceries();
    setgroceryName('')
    setstoreLocation('')
    setTimeout(() => {
      setflashMessage([false, '', []])
    }, 4000);
  })
}

const handelUpdatedStatus = (id) => {
  const formData = new FormData
  const url = '/api/update-grocery.php';
  formData.append("id", id);
  fetch(url, {
    method: 'POST',
    body: formData
  }).then((res) => res.json()).then((data) => getGroceries())
}

const deleteGrocery = (id) => {
  const formData = new FormData
  const url = '/api/delete-grocery.php';
  formData.append("id", id);
  fetch(url, {
    method: 'POST',
    body: formData
  }).then((res) => res.json()).then((data) => {
    if (data == 'Success') {
      setflashMessage([true, 'Grocery Deleted', 'green'])
    } else {
      setflashMessage([true, 'Error Occured When Deleting Grocery', 'red'])
    }
    getGroceries()
    setTimeout(() => {
      setflashMessage([false, '', []])
    }, 4000);
  }) 
}

// useEffect(() => {
//     console.log(grycState)
// }, [grycState])
  return (
    <div className='container'>
      <div className="header">
        <h1>Grocery List</h1>
      </div>

      <div className="search">
          <input onChange={(txt) => {
            if (txt.target.value !== '') {
              setgroceryList(initialGrocery.filter((grocy) => {return grocy.name.toLowerCase().includes(txt.target.value.toLowerCase())}))
            } else {
              setgroceryList(initialGrocery)
            }
          }} type="text" placeholder="Search grocery list" />
          {/* <button>Search</button> */}
        </div>

        <button className='addGrocery' onClick={() => setaddGrocery(true)}><Add  style={{marginRight: 5, fontSize: 20}} /> Add Grocery</button>

        <div className="allGroceries">
          <div style={{display: flashMessage[0] ? 'flex' : 'none', backgroundColor: flashMessage[2]}} className="flashMessage">
            {flashMessage[1]}
          </div>
          <h1>All Groceries</h1>
          <div className="groceries_container">
            {groceryList.length > 0 ? groceryList.map((grocery) => {
              return (
                <div className="grocery">
                  <div className="checkbox">
                    <input onChange={() => handelUpdatedStatus(grocery.id)} checked={grocery.bought == 'true'} type="checkbox" name="" id="" />
                  </div>
                  <div className="item_con">
                    <h3 className='item'>{grocery.name}</h3>
                    <span className='location'><LocationOn style={{color: '#007BFF'}} className='location' /> {grocery.store}</span>
                  </div>
                  <div className="remove">
                    <button onClick={() => deleteGrocery(grocery.id)}><Delete style={{color: 'red'}} /></button>
                  </div>
                </div>
              )
            }) : <h3 style={{marginTop: 20, fontWeight: 500, padding: 20}}>No Grocery Found</h3>}
          </div>
        </div>
    
        <div style={{display: addGrocery ? 'flex' : 'none'}} className='addGroceryContainer'>
            <h2>Add New Grocery</h2>

            <div className="field">
              <span>Grocery Name</span>
              <input value={groceryName} onChange={(val) => setgroceryName(val.target.value)} type="text" placeholder="Enter grocery name" />
            </div>

            <div className="field">
              <span>Store Loacation</span>
              <input value={storeLocation} onChange={(val) => setstoreLocation(val.target.value)} type="text" placeholder="Enter store location" />
            </div>

            <div className="submitContainer">
            <button className='addbtn' onClick={() => handelSubmit()}>Add</button><button onClick={() => setaddGrocery(false)} className='cancelbtn'>Cancel</button>
            </div>
        </div>
        <br />
    </div>
  );
}

export default App;
